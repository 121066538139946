import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useDashboard } from '../contexts/DashboardContext';
import { getIdToken } from 'firebase/auth';
import { auth, db } from '../services/firebase';
import { Container, Grid, Paper } from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import axios from 'axios';
import DataTable from '../components/DataTable';
import Sidebar from '../components/Sidebar';

function MainUI() {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [aiLoading, setAILoading] = useState(false);
  const [messageHistory, setMessageHistory] = useState([]);
  const { currentUser, companyName } = useAuth();
  const {
    wholesalerContractMap,
    selectedWholesaler,
    setSelectedWholesaler,
    selectedContract,
    setSelectedContract,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    insuredName,
    setInsuredName,
    policyNumber,
    setPolicyNumber,
    insuranceType,
    setInsuranceType,
    coverholderName,
    setCoverholderName,
    state,
    setState,
    tableData,
    setTableData,
    latePayments,
    setLatePayments,
    paymentsSummary,
    setPaymentsSummary,
    sanctionsData,
    setSanctionsData,
  } = useDashboard();

  const handleContractSelect = (contractId) => {
    setSelectedContract(contractId);
    const selectedWholesalerId = Object.keys(wholesalerContractMap).find(
      wholesalerId => wholesalerContractMap[wholesalerId].contracts.some(contract => contract.id === contractId)
    );
    if (selectedWholesalerId) {
      setSelectedWholesaler(selectedWholesalerId);
    }
  };

  const fetchSanctionsData = async (wholesalerId) => {
    if (!wholesalerId) {
      setSanctionsData([]);
      return;
    }

    try {
      const customersRef = collection(db, 'users', companyName, 'wholesalers', wholesalerId, 'customers');
      const querySnapshot = await getDocs(customersRef);
      
      const sanctionsData = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          'Insured Name': data['Insured Name'],
          status: data.status,
          sanctions: data.sanctions || []
        };
      });

      setSanctionsData(sanctionsData);
    } catch (err) {
      console.error('Failed to fetch sanctions data:', err);
      setError('Failed to fetch sanctions data');
    }
  };

  const handleSubmit = async (e, filterOptions = null) => {
    e?.preventDefault();
    setError('');
    setLoading(true);

    const wholesaler = filterOptions?.wholesaler_id || selectedWholesaler;
    const start = filterOptions?.start_date || startDate;
    const end = filterOptions?.end_date || endDate;

    if (!wholesaler || !start || !end) {
      setError('Please fill in wholesaler, start date, and end date');
      setLoading(false);
      return;
    }

    const payload = {
      user_id: companyName,
      wholesaler_id: wholesaler,
      start_date: start,
      end_date: end,
      Insured_Name: insuredName,
      Policy_Number: policyNumber,
      Insurance_Type: insuranceType,
      Coverholder_Name: coverholderName,
      State: state
    };

    const contract = filterOptions?.contract_id || selectedContract;
    if (contract) {
      payload.Contract_ID = contract;
    }

    try {
      const idToken = await getIdToken(auth.currentUser);
      const headers = {
        Authorization: `Bearer ${idToken}`
      };

      const [tableResponse, summaryResponse] = await Promise.all([
        axios.post('https://europe-west2-biminsurance-d5783.cloudfunctions.net/get_filtered_table', payload, { headers }),
        axios.post('https://europe-west2-biminsurance-d5783.cloudfunctions.net/get_payments_summary', payload, { headers })
      ]);

      let parsedData = tableResponse.data;
      if (typeof parsedData === 'string') {
        parsedData = parsedData.replace(/:\s*NaN/g, ': "-"');
        try {
          parsedData = JSON.parse(parsedData);
        } catch (parseError) {
          console.error('Error parsing response data:', parseError);
          setError('Error parsing response data');
          setLoading(false);
          return;
        }
      }

      if (parsedData && parsedData.rows) {
        const processedRows = parsedData.rows.map(row => 
          Object.fromEntries(
            Object.entries(row).map(([key, value]) => 
              [key, (value === null || value === undefined || Number.isNaN(value)) ? "-" : value]
            )
          )
        );
        setTableData(processedRows);
        setLatePayments(parsedData.late_payments || {});
      } else {
        console.error('Received invalid data format:', parsedData);
        setError('No data found for the selected filters');
        setTableData([]);
        setLatePayments({});
      }

      setPaymentsSummary(summaryResponse.data.summary);

      await fetchSanctionsData(wholesaler);
    } catch (err) {
      setError('Failed to fetch data');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAIQuery = async (query) => {
    setAILoading(true);
    try {
      const idToken = await getIdToken(auth.currentUser);
      const response = await axios.post('https://europe-west2-biminsurance-d5783.cloudfunctions.net/extract_filtering_options', { query }, {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      });

      const updatedMessageHistory = [...messageHistory, { role: 'user', content: query }];
      setMessageHistory(updatedMessageHistory);

      const { wholesaler_id, contract_id, start_date, end_date } = response.data;
      
      if (wholesaler_id) setSelectedWholesaler(wholesaler_id);
      if (contract_id) setSelectedContract(contract_id);
      if (start_date) setStartDate(start_date);
      if (end_date) setEndDate(end_date);

      if (response.data.ai_response) {
        setMessageHistory([...updatedMessageHistory, { role: 'assistant', content: response.data.ai_response }]);
      }

      if (wholesaler_id || start_date || end_date) {
        await handleSubmit(null, { wholesaler_id, contract_id, start_date, end_date });
      }
    } catch (err) {
      setError('Failed to process AI query');
      console.error(err);
    } finally {
      setAILoading(false);
    }
  };

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3} lg={2}>
          <Sidebar
            wholesalerContractMap={wholesalerContractMap}
            selectedWholesaler={selectedWholesaler}
            setSelectedWholesaler={setSelectedWholesaler}
            selectedContract={selectedContract}
            setSelectedContract={setSelectedContract}
            handleContractSelect={handleContractSelect}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            insuredName={insuredName}
            setInsuredName={setInsuredName}
            policyNumber={policyNumber}
            setPolicyNumber={setPolicyNumber}
            insuranceType={insuranceType}
            setInsuranceType={setInsuranceType}
            coverholderName={coverholderName}
            setCoverholderName={setCoverholderName}
            state={state}
            setState={setState}
            handleSubmit={handleSubmit}
            loading={loading}
            error={error}
            onAIQuery={handleAIQuery}
            aiLoading={aiLoading}
            messageHistory={messageHistory}
          />
        </Grid>
        <Grid item xs={12} md={9} lg={10}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
            <DataTable data={tableData} latePayments={latePayments} />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}

export default MainUI;