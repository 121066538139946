import React, { useState } from 'react';
import { Typography, Box, IconButton, Collapse, List, ListItem, ListItemText } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';

function Sanctions({ sanctionsData }) {
  const safeSanctionsData = sanctionsData || [];
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const renderSanctions = (sanctions) => {
    return sanctions.map((sanction, index) => (
      <Box key={index} sx={{ mt: 1, ml: 2 }}>
        {Object.entries(sanction).map(([key, value]) => (
          <Typography key={key} variant="body2">
            {key}: {Array.isArray(value) ? value.join(', ') : value}
          </Typography>
        ))}
      </Box>
    ));
  };

  const renderStatusIcon = (status) => {
    switch (status) {
      case 'No Sanction':
        return <CheckCircleIcon sx={{ color: 'green' }} />;
      case 'Pending':
        return <CircularProgress size={20} />;
      case 'Sanctioned':
        return <CancelIcon sx={{ color: 'red' }} />;
      default:
        return null;
    }
  };

  const getOverallStatus = () => {
    if (safeSanctionsData.length === 0) return 'No Data';
    if (safeSanctionsData.some(customer => customer.status === 'Sanctioned')) return 'Sanctioned';
    if (safeSanctionsData.every(customer => customer.status === 'No Sanction')) return 'No Sanction';
    return 'Pending';
  };

  const renderOverallStatusIcon = () => {
    const status = getOverallStatus();
    return renderStatusIcon(status);
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" gutterBottom>
          Sanctions
        </Typography>
        <Box display="flex" alignItems="center">
          {renderOverallStatusIcon()}
          <IconButton onClick={toggleExpand} size="small" sx={{ ml: 1 }}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </Box>
      </Box>
      <Collapse in={expanded}>
        <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
          {safeSanctionsData.length > 0 ? (
            <List>
              {safeSanctionsData.map((customer, index) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={
                      <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography component="span">{customer['Insured Name']}</Typography>
                        {renderStatusIcon(customer.status)}
                      </Box>
                    }
                    secondary={
                      <>
                        Status: {customer.status}
                        {customer.status === 'Sanctioned' && renderSanctions(customer.sanctions)}
                      </>
                    }
                  />
                </ListItem>
              ))}
            </List>
          ) : (
            <Typography variant="body2">No sanctions data available.</Typography>
          )}
        </Box>
      </Collapse>
    </Box>
  );
}

export default Sanctions;