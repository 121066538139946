import React, { useState, useEffect, useMemo } from 'react';
import { db } from '../services/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { getIdToken } from 'firebase/auth';
import { auth } from '../services/firebase';
import { Typography, Box, Select, MenuItem, TextField, Button, Grid, CircularProgress, Tooltip } from '@mui/material';
import { useDashboard } from '../contexts/DashboardContext';

function EditData({ companyName }) {
  const { wholesalerContractMap } = useDashboard();
  const [wholesalerPolicyMap, setWholesalerPolicyMap] = useState({});
  const [selectedWholesaler, setSelectedWholesaler] = useState('');
  const [selectedPolicy, setSelectedPolicy] = useState('');
  const [month, setMonth] = useState('');
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchPolicies = async () => {
      const tempWholesalerPolicyMap = {};
      for (const [wholesalerId, contracts] of Object.entries(wholesalerContractMap)) {
        tempWholesalerPolicyMap[wholesalerId] = [];
        const policiesCollection = collection(db, `users/${companyName}/wholesalers/${wholesalerId}/policies`);
        const policiesSnapshot = await getDocs(policiesCollection);
        policiesSnapshot.docs.forEach(policyDoc => {
          tempWholesalerPolicyMap[wholesalerId].push(policyDoc.id);
        });
      }
      setWholesalerPolicyMap(tempWholesalerPolicyMap);
    };

    fetchPolicies();
  }, [companyName, wholesalerContractMap]);

  const allPolicies = useMemo(() => 
    Object.values(wholesalerPolicyMap).flat()
  , [wholesalerPolicyMap]);

  const handleWholesalerChange = (e) => {
    setSelectedWholesaler(e.target.value);
    setSelectedPolicy('');
  };

  const handlePolicyChange = (e) => {
    setSelectedPolicy(e.target.value);
    const selectedWholesalerId = Object.keys(wholesalerPolicyMap).find(
      wholesalerId => wholesalerPolicyMap[wholesalerId].includes(e.target.value)
    );
    setSelectedWholesaler(selectedWholesalerId);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsLoading(true);

    if (!selectedWholesaler || !selectedPolicy || !month || value === '') {
      setError('Please fill in all fields');
      setIsLoading(false);
      return;
    }

    try {
      const idToken = await getIdToken(auth.currentUser);
      const response = await fetch('https://europe-west2-biminsurance-d5783.cloudfunctions.net/update_cell', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({
          user_id: companyName,
          wholesaler_id: selectedWholesaler,
          policy_number: selectedPolicy,
          month: month,
          value: parseFloat(value)
        })
      });

      if (!response.ok) {
        throw new Error('Failed to update cell');
      }

      setSuccess('Cell updated successfully');
      setMonth('');
      setValue('');
    } catch (err) {
      setError('Failed to update cell: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Typography component="h2" variant="h6" gutterBottom>
        Edit Data
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      {success && <Typography color="primary">{success}</Typography>}
      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CircularProgress />
        </Box>
      )}
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Select
              value={selectedWholesaler}
              onChange={handleWholesalerChange}
              displayEmpty
              fullWidth
              disabled={isLoading}
            >
              <MenuItem value="" disabled>Select Wholesaler</MenuItem>
              {Object.keys(wholesalerPolicyMap).map((wholesaler) => (
                <MenuItem key={wholesaler} value={wholesaler}>
                  {wholesaler}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Select
              value={selectedPolicy}
              onChange={handlePolicyChange}
              displayEmpty
              fullWidth
              disabled={isLoading}
            >
              <MenuItem value="" disabled>Select Policy Number</MenuItem>
              {(selectedWholesaler ? wholesalerPolicyMap[selectedWholesaler] : allPolicies).map((policy) => (
                <MenuItem key={policy} value={policy}>
                  {policy}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Tooltip title="Use format YYYY_MM" placement="top-start">
              <TextField
                fullWidth
                label="Month"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
                disabled={isLoading}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="New Value"
              type="text"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              disabled={isLoading}
              inputProps={{
                pattern: "^-?[0-9]*\\.?[0-9]*$"
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isLoading}
            >
              Update Cell
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default EditData;