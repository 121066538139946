import React, { useState, useEffect } from 'react';
import { db } from '../services/firebase';
import { collection, getDocs, doc, setDoc, updateDoc, deleteDoc, writeBatch } from 'firebase/firestore';
import { TextField, Typography, Box, List, ListItem, ListItemText, IconButton, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Collapse, Paper, Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(0, 0),
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    fontWeight: 500,
  },
  '& .MuiListItemText-secondary': {
    color: theme.palette.text.secondary,
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 500,
  marginBottom: theme.spacing(1),
}));

function ManageWholesalers({ companyName, wholesalerContractMap, setWholesalerContractMap }) {
  const [expandedWholesaler, setExpandedWholesaler] = useState(null);
  const [editingWholesaler, setEditingWholesaler] = useState(null);
  const [newWholesaler, setNewWholesaler] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [error, setError] = useState('');

  const handleExpand = (wholesalerId) => {
    setExpandedWholesaler(expandedWholesaler === wholesalerId ? null : wholesalerId);
    setEditingWholesaler(null);
  };

  const handleEdit = (wholesaler) => {
    setExpandedWholesaler(wholesaler.id);
    setEditingWholesaler(wholesaler);
  };

  const handleSave = async (wholesaler) => {
    try {
      const wholesalerId = wholesaler.id || wholesaler.name.replace(/\s+/g, '_');
      const wholesalerRef = doc(db, `users/${companyName}/wholesalers`, wholesalerId);
      const wholesalerData = {
        name: wholesaler.name,
        address: wholesaler.address || ''
      };
      
      if (wholesaler.id) {
        await updateDoc(wholesalerRef, wholesalerData);
      } else {
        await setDoc(wholesalerRef, wholesalerData);
      }
      
      setEditingWholesaler(null);
      setExpandedWholesaler(null);
      setNewWholesaler(null);
      
      setWholesalerContractMap(prevMap => ({
        ...prevMap,
        [wholesalerId]: { ...wholesalerData, id: wholesalerId, contracts: [] }
      }));
    } catch (err) {
      setError('Failed to save wholesaler');
      console.error(err);
    }
  };

  const handleCancel = () => {
    setEditingWholesaler(null);
    setExpandedWholesaler(null);
    setNewWholesaler(null);
  };

  const handleDelete = (wholesaler) => {
    setDeleteConfirmation(wholesaler);
  };

  const deleteCollection = async (collectionRef) => {
    const batch = writeBatch(db);
    const snapshot = await getDocs(collectionRef);
    
    snapshot.docs.forEach((doc) => {
      batch.delete(doc.ref);
    });
    
    await batch.commit();
  };

  const confirmDelete = async () => {
    if (!deleteConfirmation) return;

    try {
      const wholesalerRef = doc(db, `users/${companyName}/wholesalers`, deleteConfirmation.id);
      const contractsRef = collection(wholesalerRef, 'contracts');
      
      // Delete contracts
      await deleteCollection(contractsRef);

      // Delete the wholesaler document
      await deleteDoc(wholesalerRef);

      setDeleteConfirmation(null);
      
      setWholesalerContractMap(prevMap => {
        const newMap = { ...prevMap };
        delete newMap[deleteConfirmation.id];
        return newMap;
      });
    } catch (err) {
      setError('Failed to delete wholesaler');
      console.error(err);
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Manage Wholesalers
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <List>
        {Object.entries(wholesalerContractMap).map(([wholesalerId, wholesalerData]) => (
          <StyledPaper key={wholesalerId} elevation={2}>
            <ListItem>
              <ListItemText primary={wholesalerData.name || wholesalerId} />
              <IconButton onClick={() => handleExpand(wholesalerId)}>
                {expandedWholesaler === wholesalerId ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
              <IconButton onClick={() => handleEdit({ id: wholesalerId, ...wholesalerData })}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDelete({ id: wholesalerId })}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
            <Collapse in={expandedWholesaler === wholesalerId} timeout="auto" unmountOnExit>
              <Box sx={{ p: 2 }}>
                {editingWholesaler && editingWholesaler.id === wholesalerId ? (
                  <Box component="form" onSubmit={(e) => { e.preventDefault(); handleSave(editingWholesaler); }}>
                    <TextField
                      fullWidth
                      label="Name"
                      value={editingWholesaler.name}
                      onChange={(e) => setEditingWholesaler({ ...editingWholesaler, name: e.target.value })}
                      margin="normal"
                    />
                    <TextField
                      fullWidth
                      label="Address"
                      value={editingWholesaler.address}
                      onChange={(e) => setEditingWholesaler({ ...editingWholesaler, address: e.target.value })}
                      margin="normal"
                    />
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                      <Button type="submit" variant="contained" color="primary" sx={{ mr: 1 }}>
                        Save
                      </Button>
                      <Button onClick={handleCancel} variant="outlined">
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <SectionTitle>Wholesaler Details:</SectionTitle>
                      <List dense>
                        <StyledListItem>
                          <StyledListItemText primary="Name" secondary={wholesalerData.name || 'Not provided'} />
                        </StyledListItem>
                        <StyledListItem>
                          <StyledListItemText primary="Address" secondary={wholesalerData.address || 'Not provided'} />
                        </StyledListItem>
                      </List>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Collapse>
          </StyledPaper>
        ))}
        {newWholesaler && (
          <StyledPaper elevation={2}>
            <ListItem>
              <ListItemText primary="New Wholesaler" />
              <IconButton onClick={() => setExpandedWholesaler('new')}>
                {expandedWholesaler === 'new' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </ListItem>
            <Collapse in={expandedWholesaler === 'new'} timeout="auto" unmountOnExit>
              <Box sx={{ p: 2 }}>
                <Box component="form" onSubmit={(e) => { e.preventDefault(); handleSave(newWholesaler); }}>
                  <TextField
                    fullWidth
                    required
                    label="Name"
                    value={newWholesaler.name}
                    onChange={(e) => setNewWholesaler({ ...newWholesaler, name: e.target.value })}
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    label="Address"
                    value={newWholesaler.address}
                    onChange={(e) => setNewWholesaler({ ...newWholesaler, address: e.target.value })}
                    margin="normal"
                  />
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type="submit" variant="contained" color="primary" sx={{ mr: 1 }}>
                      Save
                    </Button>
                    <Button onClick={handleCancel} variant="outlined">
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Collapse>
          </StyledPaper>
        )}
      </List>
      <Button
        startIcon={<AddIcon />}
        onClick={() => {
          setNewWholesaler({ name: '', address: '' });
          setExpandedWholesaler('new');
        }}
        sx={{ mt: 2 }}
      >
        Add New Wholesaler
      </Button>
      <Dialog
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {deleteConfirmation?.id}? All data and contracts for this wholesaler will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmation(null)}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ManageWholesalers;