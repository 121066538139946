import React, { useState, useEffect } from 'react';
import { db } from '../services/firebase';
import { collection, getDocs, doc, setDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { TextField, Typography, Box, List, ListItem, ListItemText, IconButton, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Collapse, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

function ManageSyndicates({ companyName }) {
  const [syndicates, setSyndicates] = useState({});
  const [expandedSyndicate, setExpandedSyndicate] = useState(null);
  const [editingSyndicate, setEditingSyndicate] = useState(null);
  const [newSyndicate, setNewSyndicate] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchSyndicates = async () => {
      const syndicatesRef = collection(db, `users/${companyName}/syndicates`);
      const syndicatesSnapshot = await getDocs(syndicatesRef);
      const syndicatesData = {};
      syndicatesSnapshot.forEach((doc) => {
        syndicatesData[doc.id] = { id: doc.id, ...doc.data() };
      });
      setSyndicates(syndicatesData);
    };

    fetchSyndicates();
  }, [companyName]);

  const handleExpand = (syndicateId) => {
    setExpandedSyndicate(expandedSyndicate === syndicateId ? null : syndicateId);
    setEditingSyndicate(null);
  };

  const handleEdit = (syndicate) => {
    setExpandedSyndicate(syndicate.id);
    setEditingSyndicate(syndicate);
  };

  const handleSave = async (syndicate) => {
    try {
      const syndicateRef = doc(db, `users/${companyName}/syndicates`, syndicate.name);
      const syndicateData = {
        name: syndicate.name,
        address: syndicate.address || ''
      };
      
      if (syndicate.id) {
        await updateDoc(syndicateRef, syndicateData);
      } else {
        await setDoc(syndicateRef, syndicateData);
      }
      
      setEditingSyndicate(null);
      setExpandedSyndicate(null);
      setNewSyndicate(null);
      
      setSyndicates(prevSyndicates => ({
        ...prevSyndicates,
        [syndicate.name]: { ...syndicateData, id: syndicate.name }
      }));
    } catch (err) {
      setError('Failed to save syndicate');
      console.error(err);
    }
  };

  const handleCancel = () => {
    setEditingSyndicate(null);
    setExpandedSyndicate(null);
    setNewSyndicate(null);
  };

  const handleDelete = (syndicate) => {
    setDeleteConfirmation(syndicate);
  };

  const confirmDelete = async () => {
    if (!deleteConfirmation) return;

    try {
      const syndicateRef = doc(db, `users/${companyName}/syndicates`, deleteConfirmation.id);
      await deleteDoc(syndicateRef);

      setDeleteConfirmation(null);
      
      setSyndicates(prevSyndicates => {
        const newSyndicates = { ...prevSyndicates };
        delete newSyndicates[deleteConfirmation.id];
        return newSyndicates;
      });
    } catch (err) {
      setError('Failed to delete syndicate');
      console.error(err);
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Manage Syndicates
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <List>
        {Object.values(syndicates).map((syndicate) => (
          <StyledPaper key={syndicate.id} elevation={2}>
            <ListItem>
              <ListItemText primary={syndicate.name} />
              <IconButton onClick={() => handleExpand(syndicate.id)}>
                {expandedSyndicate === syndicate.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
              <IconButton onClick={() => handleEdit(syndicate)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDelete(syndicate)}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
            <Collapse in={expandedSyndicate === syndicate.id} timeout="auto" unmountOnExit>
              <Box sx={{ p: 2 }}>
                {editingSyndicate && editingSyndicate.id === syndicate.id ? (
                  <Box component="form" onSubmit={(e) => { e.preventDefault(); handleSave(editingSyndicate); }}>
                    <TextField
                      fullWidth
                      label="Name"
                      value={editingSyndicate.name}
                      onChange={(e) => setEditingSyndicate({ ...editingSyndicate, name: e.target.value })}
                      margin="normal"
                    />
                    <TextField
                      fullWidth
                      label="Address"
                      value={editingSyndicate.address}
                      onChange={(e) => setEditingSyndicate({ ...editingSyndicate, address: e.target.value })}
                      margin="normal"
                    />
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                      <Button type="submit" variant="contained" color="primary" sx={{ mr: 1 }}>
                        Save
                      </Button>
                      <Button onClick={handleCancel} variant="outlined">
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <List dense>
                    <ListItem>
                      <ListItemText primary="Name" secondary={syndicate.name} />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Address" secondary={syndicate.address || 'Not provided'} />
                    </ListItem>
                  </List>
                )}
              </Box>
            </Collapse>
          </StyledPaper>
        ))}
        {newSyndicate && (
          <StyledPaper elevation={2}>
            <ListItem>
              <ListItemText primary="New Syndicate" />
              <IconButton onClick={() => setExpandedSyndicate('new')}>
                {expandedSyndicate === 'new' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </ListItem>
            <Collapse in={expandedSyndicate === 'new'} timeout="auto" unmountOnExit>
              <Box sx={{ p: 2 }}>
                <Box component="form" onSubmit={(e) => { e.preventDefault(); handleSave(newSyndicate); }}>
                  <TextField
                    fullWidth
                    required
                    label="Name"
                    value={newSyndicate.name}
                    onChange={(e) => setNewSyndicate({ ...newSyndicate, name: e.target.value })}
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    label="Address"
                    value={newSyndicate.address}
                    onChange={(e) => setNewSyndicate({ ...newSyndicate, address: e.target.value })}
                    margin="normal"
                  />
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type="submit" variant="contained" color="primary" sx={{ mr: 1 }}>
                      Save
                    </Button>
                    <Button onClick={handleCancel} variant="outlined">
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Collapse>
          </StyledPaper>
        )}
      </List>
      <Button
        startIcon={<AddIcon />}
        onClick={() => {
          setNewSyndicate({ name: '', address: '' });
          setExpandedSyndicate('new');
        }}
        sx={{ mt: 2 }}
      >
        Add New Syndicate
      </Button>
      <Dialog
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {deleteConfirmation?.name}? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmation(null)}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ManageSyndicates;