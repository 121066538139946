import React, { useState, useEffect } from 'react';
import { db } from '../services/firebase';
import { collection, getDocs, doc, setDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { TextField, Typography, Box, List, ListItem, ListItemText, IconButton, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem, Collapse, Paper, Grid, Divider, CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import { useDashboard } from '../contexts/DashboardContext';
import { styled } from '@mui/material/styles';

const commonColumns = [
  "Policy Number",
  "Insured Name",
  "Insurance Type",
  "Inception Date",
  "Expiry Date",
  "State",
  "Payment Plan",
  "Surplus Line Broker",
  "Broker Address",
  "Broker License Number",
  "Effective Date of Transaction",
  "Gross Premium Paid",
  "Period Starting"
];

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(0, 0),
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    fontWeight: 500,
  },
  '& .MuiListItemText-secondary': {
    color: theme.palette.text.secondary,
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: 500,
  marginBottom: theme.spacing(1),
}));

function ManageContracts({ companyName }) {
  const { wholesalerContractMap, setWholesalerContractMap } = useDashboard();
  const [selectedWholesaler, setSelectedWholesaler] = useState('');
  const [expandedContract, setExpandedContract] = useState(null);
  const [editingContract, setEditingContract] = useState(null);
  const [newContract, setNewContract] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [error, setError] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [syndicates, setSyndicates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchSyndicates = async () => {
      const syndicatesRef = collection(db, `users/${companyName}/syndicates`);
      const syndicatesSnapshot = await getDocs(syndicatesRef);
      const syndicatesList = syndicatesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setSyndicates(syndicatesList);
    };

    fetchSyndicates();
  }, [companyName]);

  useEffect(() => {
    const fetchUploadedFiles = async () => {
      if (selectedWholesaler) {
        const contractsRef = collection(db, `users/${companyName}/wholesalers/${selectedWholesaler}/contracts`);
        const contractsSnapshot = await getDocs(contractsRef);
        const filesPromises = contractsSnapshot.docs.map(async (contractDoc) => {
          const bordereauRef = collection(db, `users/${companyName}/wholesalers/${selectedWholesaler}/contracts/${contractDoc.id}/raw_bordereaux`);
          const bordereauSnapshot = await getDocs(bordereauRef);
          return { 
            contractId: contractDoc.id, 
            files: bordereauSnapshot.docs.map(doc => doc.data().fileName)
          };
        });
        const filesResults = await Promise.all(filesPromises);
        const filesObj = {};
        filesResults.forEach(result => {
          filesObj[result.contractId] = result.files;
        });
        setUploadedFiles(filesObj);
      }
    };

    fetchUploadedFiles();
  }, [selectedWholesaler, companyName]);

  const handleExpand = (contractId) => {
    setExpandedContract(expandedContract === contractId ? null : contractId);
    setEditingContract(null);
  };

  const handleEdit = (contract) => {
    setExpandedContract(contract.id);
    setEditingContract(contract);
  };

  const handleSave = async (contract) => {
    setIsLoading(true);
    try {
      const contractRef = doc(db, `users/${companyName}/wholesalers/${selectedWholesaler}/contracts`, contract.id);
      const contractData = {
        id: contract.id,
        column_mapping: contract.column_mapping,
        syndicate_id: contract.syndicate_id,
        date_format: contract.date_format
      };
      
      if (editingContract) {
        await updateDoc(contractRef, contractData);
      } else {
        await setDoc(contractRef, contractData);
      }
      
      setEditingContract(null);
      setExpandedContract(null);
      setNewContract(null);
      
      setWholesalerContractMap(prevMap => ({
        ...prevMap,
        [selectedWholesaler]: {
          ...prevMap[selectedWholesaler],
          contracts: editingContract
            ? prevMap[selectedWholesaler].contracts.map(c => 
                c.id === contract.id ? contractData : c
              )
            : [...prevMap[selectedWholesaler].contracts, contractData]
        }
      }));
    } catch (err) {
      setError('Failed to save contract');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setEditingContract(null);
    setExpandedContract(null);
    setNewContract(null);
  };

  const handleDelete = (contract) => {
    setDeleteConfirmation(contract);
  };

  const confirmDelete = async () => {
    if (!deleteConfirmation) return;

    try {
      const contractRef = doc(db, `users/${companyName}/wholesalers/${selectedWholesaler}/contracts`, deleteConfirmation.id);
      await deleteDoc(contractRef);

      setDeleteConfirmation(null);
      
      setWholesalerContractMap(prevMap => ({
        ...prevMap,
        [selectedWholesaler]: {
          ...prevMap[selectedWholesaler],
          contracts: prevMap[selectedWholesaler].contracts.filter(c => c.id !== deleteConfirmation.id)
        }
      }));
    } catch (err) {
      setError('Failed to delete contract');
      console.error(err);
    }
  };

  return (
    <Box>
      <Typography component="h2" variant="h6" gutterBottom>
        Manage Contracts
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <Select
        value={selectedWholesaler}
        onChange={(e) => setSelectedWholesaler(e.target.value)}
        displayEmpty
        fullWidth
        sx={{ mb: 2 }}
      >
        <MenuItem value="" disabled>Select Wholesaler</MenuItem>
        {Object.keys(wholesalerContractMap).map((wholesalerId) => (
          <MenuItem key={wholesalerId} value={wholesalerId}>
            {wholesalerContractMap[wholesalerId].name}
          </MenuItem>
        ))}
      </Select>
      {selectedWholesaler && (
        <List>
          {wholesalerContractMap[selectedWholesaler].contracts.map((contract) => (
            <StyledPaper key={contract.id} elevation={2}>
              <ListItem>
                <ListItemText primary={contract.id} />
                <IconButton onClick={() => handleEdit(contract)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDelete(contract)}>
                  <DeleteIcon />
                </IconButton>
                <IconButton onClick={() => handleExpand(contract.id)}>
                  {expandedContract === contract.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </ListItem>
              <Collapse in={expandedContract === contract.id} timeout="auto" unmountOnExit>
                <Box sx={{ p: 2 }}>
                  {editingContract && editingContract.id === contract.id ? (
                    <Box component="form" onSubmit={(e) => { e.preventDefault(); handleSave(editingContract); }}>
                      <TextField
                        fullWidth
                        required
                        label="Contract ID"
                        value={editingContract.id}
                        onChange={(e) => setEditingContract({ ...editingContract, id: e.target.value })}
                        margin="normal"
                      />
                      {commonColumns.map((column) => (
                        <TextField
                          key={column}
                          fullWidth
                          required
                          label={`Column for ${column}`}
                          value={editingContract.column_mapping[column] || ''}
                          onChange={(e) => setEditingContract({
                            ...editingContract,
                            column_mapping: { ...editingContract.column_mapping, [column]: e.target.value }
                          })}
                          margin="normal"
                        />
                      ))}
                      <Select
                        fullWidth
                        required
                        label="Date Format"
                        value={editingContract.date_format || ''}
                        onChange={(e) => setEditingContract({ ...editingContract, date_format: e.target.value })}
                        margin="normal"
                        displayEmpty
                      >
                        <MenuItem value="" disabled>Select Date Format</MenuItem>
                        <MenuItem value="EN">EN</MenuItem>
                        <MenuItem value="US">US</MenuItem>
                      </Select>
                      <Select
                        fullWidth
                        required
                        label="Syndicate"
                        value={editingContract.syndicate_id || ''}
                        onChange={(e) => setEditingContract({ ...editingContract, syndicate_id: e.target.value })}
                        margin="normal"
                        displayEmpty
                      >
                        <MenuItem value="" disabled>Select Syndicate</MenuItem>
                        {syndicates.map((syndicate) => (
                          <MenuItem key={syndicate.id} value={syndicate.id}>
                            {syndicate.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button type="submit" variant="contained" color="primary" sx={{ mr: 1 }}>
                          Save
                        </Button>
                        <Button onClick={handleCancel} variant="outlined">
                          Cancel
                        </Button>
                      </Box>
                    </Box>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <SectionTitle>Column Mapping:</SectionTitle>
                        <List dense>
                          {Object.entries(contract.column_mapping).map(([key, value]) => (
                            <StyledListItem key={key}>
                              <StyledListItemText primary={key} secondary={value} />
                            </StyledListItem>
                          ))}
                        </List>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <SectionTitle>Date Format:</SectionTitle>
                        <Typography>{contract.date_format || 'Not set'}</Typography>
                        <Divider sx={{ my: 2 }} />
                        <SectionTitle>Syndicate:</SectionTitle>
                        <Typography>
                          {syndicates.find(s => s.id === contract.syndicate_id)?.name || 'Not assigned'}
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <SectionTitle>Uploaded Files:</SectionTitle>
                        <List dense>
                          {uploadedFiles[contract.id]?.map((file) => (
                            <StyledListItem key={file}>
                              <StyledListItemText primary={file} />
                            </StyledListItem>
                          ))}
                        </List>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Collapse>
            </StyledPaper>
          ))}
          {newContract && (
            <StyledPaper elevation={2}>
              <ListItem>
                <ListItemText primary="New Contract" />
                <IconButton onClick={() => setExpandedContract('new')}>
                  {expandedContract === 'new' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </ListItem>
              <Collapse in={expandedContract === 'new'} timeout="auto" unmountOnExit>
                <Box sx={{ p: 2 }}>
                  <Box component="form" onSubmit={(e) => { e.preventDefault(); handleSave(newContract); }}>
                    <TextField
                      fullWidth
                      required
                      label="Contract ID"
                      value={newContract.id}
                      onChange={(e) => setNewContract({ ...newContract, id: e.target.value })}
                      margin="normal"
                    />
                    {commonColumns.map((column) => (
                      <TextField
                        key={column}
                        fullWidth
                        required
                        label={`Column for ${column}`}
                        value={newContract.column_mapping[column] || ''}
                        onChange={(e) => setNewContract({
                          ...newContract,
                          column_mapping: { ...newContract.column_mapping, [column]: e.target.value }
                        })}
                        margin="normal"
                      />
                    ))}
                    <Select
                      fullWidth
                      required
                      label="Date Format"
                      value={newContract.date_format || ''}
                      onChange={(e) => setNewContract({ ...newContract, date_format: e.target.value })}
                      margin="normal"
                      displayEmpty
                      sx={{ my: 2 }}
                    >
                      <MenuItem value="" disabled>Select Date Format</MenuItem>
                      <MenuItem value="EN">EN</MenuItem>
                      <MenuItem value="US">US</MenuItem>
                    </Select>
                    <Select
                      fullWidth
                      required
                      label="Syndicate"
                      value={newContract.syndicate_id || ''}
                      onChange={(e) => setNewContract({ ...newContract, syndicate_id: e.target.value })}
                      margin="normal"
                      displayEmpty
                    >
                      <MenuItem value="" disabled>Select Syndicate</MenuItem>
                      {syndicates.map((syndicate) => (
                        <MenuItem key={syndicate.id} value={syndicate.id}>
                          {syndicate.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                      <Button type="submit" variant="contained" color="primary" sx={{ mr: 1 }} disabled={isLoading}>
                        {isLoading ? <CircularProgress size={24} /> : 'Save'}
                      </Button>
                      <Button onClick={handleCancel} variant="outlined" disabled={isLoading}>
                        Cancel
                      </Button>
                    </Box>
                  </Box>
                </Box>
                </Collapse>
            </StyledPaper>
          )}
        </List>
      )}
      {selectedWholesaler && (
        <Button
          startIcon={<AddIcon />}
          onClick={() => {
            setNewContract({ id: '', column_mapping: {}, syndicate_id: '', date_format: '' });
            setExpandedContract('new');
          }}
          sx={{ mt: 2 }}
        >
          Add New Contract
        </Button>
      )}
      <Dialog
        open={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete {deleteConfirmation?.id}? All data and bordereaux for this contract will be lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmation(null)}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ManageContracts;