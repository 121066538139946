import React, { useState, useEffect } from 'react';
import { db, storage } from '../services/firebase';
import { collection, getDocs, addDoc, query, where, onSnapshot } from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import { TextField, Typography, Box, Select, MenuItem, List, ListItem, ListItemText, Grid, Paper } from '@mui/material';
import { FetchSubmitButton } from './Buttons';
import { useDashboard } from '../contexts/DashboardContext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress from '@mui/material/CircularProgress';

function AddBordereaux({ companyName }) {
  const { wholesalerContractMap } = useDashboard();
  const [files, setFiles] = useState([]);
  const [selectedWholesaler, setSelectedWholesaler] = useState('');
  const [selectedContract, setSelectedContract] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [cleanedSheets, setCleanedSheets] = useState([]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 30) {
      setError('You can only upload up to 30 files at once.');
      return;
    }
    setFiles(selectedFiles);
  };

  const handleWholesalerChange = (e) => {
    setSelectedWholesaler(e.target.value);
    setSelectedContract('');
  };

  const handleContractChange = (e) => {
    setSelectedContract(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsLoading(true);
  
    if (files.length === 0 || !selectedWholesaler || !selectedContract) {
      setError('Please select at least one file, a wholesaler, and a contract');
      setIsLoading(false);
      return;
    }
  
    try {
      const uploadedFileNames = [];
      for (const file of files) {
        const sanitizedFileName = file.name
          .replace(/[^a-zA-Z0-9.-]/g, '_')
          .replace(/_{2,}/g, '_')       
          .replace(/^_|_$/g, ''); 
        const storageRef = ref(storage, `users/${companyName}/wholesalers/${selectedWholesaler}/contracts/${selectedContract}/bordereaux/${sanitizedFileName}`);
        
        const uploadResult = await uploadBytes(storageRef, file);
    
        await addDoc(collection(db, `users/${companyName}/wholesalers/${selectedWholesaler}/contracts/${selectedContract}/raw_bordereaux`), {
          fileName: sanitizedFileName,
          uploadDate: new Date(),
          prefix: `users/${companyName}/wholesalers/${selectedWholesaler}/contracts/${selectedContract}/bordereaux/${sanitizedFileName}`,
        });

        uploadedFileNames.push(sanitizedFileName);
      }
  
      setSuccess('Bordereaux uploaded successfully! Please wait a couple of minutes for the update to propagate.');
      setUploadedFiles(uploadedFileNames);
    } catch (err) {
      let errorMessage = 'Failed to upload bordereaux';
      if (err.code === 'storage/unauthorized') {
        errorMessage += ': Unauthorized access. Please check your permissions.';
      } else if (err.code === 'storage/canceled') {
        errorMessage += ': Upload was canceled.';
      } else if (err.code === 'storage/unknown') {
        errorMessage += ': An unknown error occurred. Please check the console for more details.';
      } else {
        errorMessage += `: ${err.message}`;
      }
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const watchCleanedBordereaux = () => {
    if (uploadedFiles.length === 0) return;

    const q = query(
      collection(db, `users/${companyName}/wholesalers/${selectedWholesaler}/contracts/${selectedContract}/cleaned_bordereaux`),
      where('original_file', 'in', uploadedFiles.map(file => `users/${companyName}/wholesalers/${selectedWholesaler}/contracts/${selectedContract}/bordereaux/${file}`))
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const updatedSheets = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCleanedSheets(updatedSheets);
    });

    return unsubscribe;
  };

  useEffect(() => {
    const unsubscribe = watchCleanedBordereaux();
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [uploadedFiles]);

  const CleanedSheetsStatus = () => (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h6" gutterBottom>
        Ingestion Status
      </Typography>
      <Grid container spacing={2}>
        {cleanedSheets.map((sheet) => {
          const uploadedDocName = sheet.original_file.split('/').pop();
          return (
            <Grid item xs={12} sm={6} md={4} key={sheet.id}>
              <Paper sx={{ p: 2, display: 'flex', alignItems: 'flex-start', height: '100%' }}>
                {sheet.status === 'Completed' && <CheckCircleIcon color="success" sx={{ mr: 1, mt: 0.5 }} />}
                {sheet.status === 'Processing' && <CircularProgress size={20} sx={{ mr: 1, mt: 0.5 }} />}
                {sheet.status === 'Failed' && <CancelIcon color="error" sx={{ mr: 1, mt: 0.5 }} />}
                <Box>
                  <Typography variant="subtitle1">
                    {uploadedDocName}: {sheet.sheet_name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Status: {sheet.status}
                  </Typography>
                  {sheet.error && <Typography variant="body2" color="text.secondary">
                    {sheet.error}
                  </Typography>}
                  {sheet.message && <Typography variant="body2" color="text.secondary">
                    {sheet.message}
                  </Typography>}
                </Box>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );

  return (
    <Box>
      <Typography component="h2" variant="h6" gutterBottom>
        Upload Bordereaux
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      {success && <Typography color="primary">{success}</Typography>}
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <Select
          value={selectedWholesaler}
          onChange={handleWholesalerChange}
          displayEmpty
          fullWidth
          sx={{ mb: 2 }}
          disabled={isLoading}
        >
          <MenuItem value="" disabled>Select Wholesaler</MenuItem>
          {Object.keys(wholesalerContractMap).map((wholesalerId) => (
            <MenuItem key={wholesalerId} value={wholesalerId}>
              {wholesalerContractMap[wholesalerId].name || wholesalerId}
            </MenuItem>
          ))}
        </Select>
        <Select
          value={selectedContract}
          onChange={handleContractChange}
          displayEmpty
          fullWidth
          sx={{ mb: 2 }}
          disabled={isLoading || !selectedWholesaler}
        >
          <MenuItem value="" disabled>Select Contract</MenuItem>
          {selectedWholesaler &&
            wholesalerContractMap[selectedWholesaler].contracts.map((contract) => (
              <MenuItem key={contract.id} value={contract.id}>
                {contract.id}
              </MenuItem>
            ))}
        </Select>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="file"
          id="bordereaux"
          name="bordereaux"
          onChange={handleFileChange}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            multiple: true,
            accept: '.xlsx,.xls,.csv'
          }}
          disabled={isLoading}
        />
        {cleanedSheets.length > 0 && <CleanedSheetsStatus />}
        <FetchSubmitButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={isLoading}
        >
          {isLoading ? 'Uploading Bordereaux...' : `Upload ${files.length} Bordereaux`}
        </FetchSubmitButton>
      </Box>
    </Box>
  );
}

export default AddBordereaux;