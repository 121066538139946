import React, { useState, useEffect } from 'react';
import { db } from '../services/firebase';
import { collection, getDocs, doc, setDoc, writeBatch } from 'firebase/firestore';
import { Typography, Box, Select, MenuItem, TextField, Button, Accordion, AccordionSummary, AccordionDetails, Grid, CircularProgress } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDashboard } from '../contexts/DashboardContext';

function ManageCustomers({ companyName }) {
  const { wholesalerContractMap } = useDashboard();
  const [selectedWholesaler, setSelectedWholesaler] = useState('');
  const [policies, setPolicies] = useState([]);
  const [premiums, setPremiums] = useState({});
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchPolicies = async () => {
      if (selectedWholesaler) {
        const policiesCollection = collection(db, `users/${companyName}/wholesalers/${selectedWholesaler}/policies`);
        const policiesSnapshot = await getDocs(policiesCollection);
        const policiesList = policiesSnapshot.docs.map(doc => {
          const policyData = doc.data();
          return {
            id: doc.id,
            insuredName: policyData['Insured Name'] || 'Unknown',
            expectedMonthlyPremium: policyData['Expected Monthly Premium'] || ''
          };
        });
        setPolicies(policiesList);
        const premiumsObj = {};
        policiesList.forEach(policy => {
          premiumsObj[policy.id] = policy.expectedMonthlyPremium.toString();
        });
        setPremiums(premiumsObj);
      }
    };

    fetchPolicies();
  }, [selectedWholesaler, companyName]);

  const handlePremiumChange = (policyId, value) => {
    setPremiums(prevPremiums => ({
      ...prevPremiums,
      [policyId]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setIsLoading(true);

    try {
      const batch = writeBatch(db);

      for (const [policyId, premium] of Object.entries(premiums)) {
        const policyRef = doc(db, `users/${companyName}/wholesalers/${selectedWholesaler}/policies`, policyId);
        batch.update(policyRef, { 'Expected Monthly Premium': parseFloat(premium) });
      }

      await batch.commit();

      setSuccess('Premiums updated successfully');
    } catch (err) {
      setError('Failed to update premiums');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Typography component="h2" variant="h6" gutterBottom>
        Manage Customer Premiums
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      {success && <Typography color="primary">{success}</Typography>}
      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CircularProgress />
        </Box>
      )}
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Select
          value={selectedWholesaler}
          onChange={(e) => setSelectedWholesaler(e.target.value)}
          displayEmpty
          fullWidth
          sx={{ mb: 3 }}
        >
          <MenuItem value="" disabled>Select Wholesaler</MenuItem>
          {Object.keys(wholesalerContractMap).map((wholesaler) => (
            <MenuItem key={wholesaler} value={wholesaler}>
              {wholesaler}
            </MenuItem>
          ))}
        </Select>
        {policies.map((policy) => (
          <Accordion key={policy.id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{`${policy.id} - ${policy.insuredName}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    label="Expected Monthly Premium"
                    value={premiums[policy.id]}
                    onChange={(e) => handlePremiumChange(policy.id, e.target.value)}
                    type="number"
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          disabled={isLoading}
        >
          Update Premiums
        </Button>
      </Box>
    </Box>
  );
}

export default ManageCustomers;