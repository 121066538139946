import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { DashboardProvider } from './contexts/DashboardContext';
import Header from './components/Header';
import Login from './pages/Login';
import MainUI from './pages/MainUI';
import ManageData from './pages/ManageData';
import theme from './theme';

function PrivateRoute({ children }) {
  const { currentUser, companyName } = useAuth();
  return currentUser && companyName ? (
    <>
      <Header />
      {children}
    </>
  ) : (
    <Navigate to="/login" />
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <DashboardProvider>
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/manage-data" element={<PrivateRoute><ManageData /></PrivateRoute>} />
              <Route path="/" element={<PrivateRoute><MainUI /></PrivateRoute>} />
            </Routes>
          </Router>
        </DashboardProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;