import React, { createContext, useState, useEffect, useContext } from 'react';
import { db } from '../services/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useAuth } from './AuthContext';

const DashboardContext = createContext();

export function useDashboard() {
  return useContext(DashboardContext);
}

export function DashboardProvider({ children }) {
  const [wholesalerContractMap, setWholesalerContractMap] = useState({});
  const [selectedWholesaler, setSelectedWholesaler] = useState('');
  const [selectedContract, setSelectedContract] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [insuredName, setInsuredName] = useState('');
  const [policyNumber, setPolicyNumber] = useState('');
  const [insuranceType, setInsuranceType] = useState('');
  const [coverholderName, setCoverholderName] = useState('');
  const [state, setState] = useState('');
  const [tableData, setTableData] = useState([]);
  const [latePayments, setLatePayments] = useState({});
  const [paymentsSummary, setPaymentsSummary] = useState({});
  const [sanctionsData, setSanctionsData] = useState([]);
  const { companyName } = useAuth();

  useEffect(() => {
    const fetchWholesalersAndContracts = async () => {
      if (companyName) {
        const wholesalersCollection = collection(db, `users/${companyName}/wholesalers`);
        const wholesalersSnapshot = await getDocs(wholesalersCollection);
        const tempWholesalerContractMap = {};

        for (const wholesalerDoc of wholesalersSnapshot.docs) {
          const wholesalerId = wholesalerDoc.id;
          const wholesalerData = wholesalerDoc.data();
          tempWholesalerContractMap[wholesalerId] = {
            ...wholesalerData,
            id: wholesalerId,
            contracts: []
          };

          const contractsCollection = collection(db, `users/${companyName}/wholesalers/${wholesalerId}/contracts`);
          const contractsSnapshot = await getDocs(contractsCollection);
          
          contractsSnapshot.docs.forEach(contractDoc => {
            tempWholesalerContractMap[wholesalerId].contracts.push({
              id: contractDoc.id,
              ...contractDoc.data()
            });
          });
        }

        setWholesalerContractMap(tempWholesalerContractMap);
      }
    };

    fetchWholesalersAndContracts();
  }, [companyName]);

  const value = {
    wholesalerContractMap,
    setWholesalerContractMap,
    selectedWholesaler,
    setSelectedWholesaler,
    selectedContract,
    setSelectedContract,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    insuredName,
    setInsuredName,
    policyNumber,
    setPolicyNumber,
    insuranceType,
    setInsuranceType,
    coverholderName,
    setCoverholderName,
    state,
    setState,
    tableData,
    setTableData,
    latePayments,
    setLatePayments,
    paymentsSummary,
    setPaymentsSummary,
    sanctionsData,
    setSanctionsData,
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
}