import React, { useState } from 'react';
import { Container, Typography, Box, Tabs, Tab } from '@mui/material';
import ManageWholesalers from '../components/ManageWholesalers';
import AddBordereaux from '../components/AddBordereaux';
import ManageCustomers from '../components/ManageCustomers';
import EditData from '../components/EditData';
import ManageContracts from '../components/ManageContracts';
import ManageSyndicates from '../components/ManageSyndicates';
import { useAuth } from '../contexts/AuthContext';
import { useDashboard } from '../contexts/DashboardContext';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function ManageData() {
  const [value, setValue] = useState(0);
  const { currentUser, companyName } = useAuth();
  const { wholesalerContractMap, setWholesalerContractMap } = useDashboard();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container component="main" maxWidth="md" sx={{ mt: 8 }}>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Manage Data
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="manage data tabs">
          <Tab label="Wholesalers" />
          <Tab label="Syndicates" />
          <Tab label="Contracts" />
          <Tab label="Add Bordereaux" />
          <Tab label="Manage Customers" />
          <Tab label="Edit Data" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ManageWholesalers 
          companyName={companyName} 
          wholesalerContractMap={wholesalerContractMap} 
          setWholesalerContractMap={setWholesalerContractMap}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ManageSyndicates companyName={companyName} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ManageContracts companyName={companyName} wholesalerContractMap={wholesalerContractMap} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AddBordereaux companyName={companyName} wholesalerContractMap={wholesalerContractMap} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ManageCustomers companyName={companyName} wholesalerContractMap={wholesalerContractMap} />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <EditData companyName={companyName} wholesalerContractMap={wholesalerContractMap} />
      </TabPanel>
    </Container>
  );
}

export default ManageData;