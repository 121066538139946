import React, { createContext, useState, useEffect, useContext } from 'react';
import { auth, db } from '../services/firebase';
import { signOut, signInWithEmailAndPassword } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [loading, setLoading] = useState(true);

  async function loginWithEmailAndPassword(email, password) {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    await fetchCompanyName(userCredential.user.uid);
    return userCredential;
  }

  async function fetchCompanyName(uid) {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('user_ids', 'array-contains', uid));
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      const companyDoc = querySnapshot.docs[0];
      setCompanyName(companyDoc.id);
    } else {
      console.error('No company found for this user');
      setCompanyName(null);
    }
  }

  function logout() {
    setCompanyName(null);
    return signOut(auth);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      if (user) {
        await fetchCompanyName(user.uid);
      } else {
        setCompanyName(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    companyName,
    loginWithEmailAndPassword,
    logout,
    fetchCompanyName
  };

  console.log(companyName);

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}