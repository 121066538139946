import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const FetchDataButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  }));

const FetchSubmitButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
    },
}));

export { FetchDataButton, FetchSubmitButton };

